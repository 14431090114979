import NAME from "./constants";

export const GET_DATA = NAME + "/GET_DATA";
export const GET_DATA_FULFILLED = NAME + "/GET_DATA_FULFILLED";
export const GET_DATA_REJECTED = NAME + "/GET_DATA_REJECTED";

export const PREVIEW_DOCUMENT = NAME + "/PREVIEW_DOCUMENT";
export const PREVIEW_DOCUMENT_FULFILLED = NAME + "/PREVIEW_DOCUMENT_FULFILLED";
export const PREVIEW_DOCUMENT_REJECTED = NAME + "/PREVIEW_DOCUMENT_REJECTED";

export const DELETE_DOCUMENT = NAME + "/DELETE_DOCUMENT";
export const DELETE_DOCUMENT_FULFILLED = NAME + "/DELETE_DOCUMENT_FULFILLED";
export const DELETE_DOCUMENT_REJECTED = NAME + "/DELETE_DOCUMENT_REJECTED";

export const SIGN_DOCUMENT = NAME + "/SIGN_DOCUMENT";
export const SIGN_DOCUMENT_FULFILLED = NAME + "/SIGN_DOCUMENT_FULFILLED";
export const SIGN_DOCUMENT_REJECTED = NAME + "/SIGN_DOCUMENT_REJECTED";

export const UPLOAD_DOCUMENT = NAME + "/UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_FULFILLED = NAME + "/UPLOAD_DOCUMENT_FULFILLED";
export const UPLOAD_DOCUMENT_REJECTED = NAME + "/UPLOAD_DOCUMENT_REJECTED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";