import NAME from "./constants";

export const getApprovants = store => store[NAME].approvants;
export const getDocuments = store => store[NAME].docs;
export const getRoles = store => store[NAME].roles;
export const loading = store => 
    store[NAME].gettingData ||
    store[NAME].gettingPreview ||
    store[NAME].deletingDocuments ||
    store[NAME].signingDocuments ||
    store[NAME].uploadingDocuments;
