import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { getRoles } from "@icarius-pages/externalDocuments/selectors";
import { uploadDocumentsAction, getDocumentsSignedAction } from "@icarius-pages/externalDocuments/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";
import FileList from "@icarius-common/fileUpload/fileList";
import FileInput from "@icarius-common/fileUpload/fileInput";

const UploadFilesAction = (props) => {

  const { open, loading, handleClose } = props;

  const [roleSelected, setRoleSelected] = useState("");
  const [files, setFiles] = useState(null);
  const [name, setName] = useState("");
  const [failedDocuments, setFailedDocuments] = useState(null);

  const dispatch = useDispatch();
  const roles = useSelector(getRoles);

  const handleSubmit = () => {
    setFailedDocuments(null);
    if (dataIsValid()) {
      dispatch(uploadDocumentsAction(files, name, roleSelected))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(openSnackbarAction({ msg: getLocalizedString("documentUploadedOK"), severity: "success" }));
            dispatch(getDocumentsSignedAction());
            handleClose();
          } else {
            setFailedDocuments(resp.data.invalidFiles)
          }
        });
    } else {
      dispatch(openSnackbarAction({ msg: getLocalizedString("fieldRequeriment"), severity: "error" }));
    }
  };

  const dataIsValid = () => {
    if (roleSelected === "" || name === "") return false;
    if (files === null) return false;
    return true;
  };

  const handleDeleteFileFromList = (file) => {
    const newFileArray = [...files];
    const indexToDelete = newFileArray.indexOf(file);
    if (indexToDelete > -1) {
      newFileArray.splice(indexToDelete, 1);
      setFiles(newFileArray.length === 0 ? null : newFileArray);
    }
  }

  if (loading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("uploadExternalDocuments")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <FormControl style={{ width: "100%", marginBottom: 20 }}>
            <InputLabel id="role-select-label">{getLocalizedString("selectDocumentRole")}</InputLabel>
            <Select
              fullWidth
              labelId="role-select-label"
              id="role-select"
              value={roleSelected}
              onChange={(e) => setRoleSelected(e.target.value)}
            >
              {
                roles.map((role) =>
                  <MenuItem
                    className={"whiteText"}
                    key={role.role}
                    value={role.role}
                  >
                    {role.text}
                  </MenuItem>
                )
              }
            </Select>
          </FormControl>
          <TextField
            value={name}
            onChange={e => setName(e.target.value.toString().slice(0, 100))}
            fullWidth
            style={{ marginBottom: 20 }}
            label={getLocalizedString("externalDocumentTitle")}
          />
          <Typography style={{ marginTop: 5, marginBottom: 30 }} className="whiteText" variant="subtitle2">
            {'La descripción que se ingresa formará parte del nombre del archivo que se carga'}
          </Typography>
          <FileInput
            acceptedFormats={['.pdf']}
            extraLabel={getLocalizedString("filesMustStartWithCode")}
            handleChange={(files) => setFiles(files)}
            preSubmitCallback={() => setFailedDocuments(null)}
            multiple
          />
          <FileList
            files={files}
            filesWithError={failedDocuments}
            handleClick={handleDeleteFileFromList}
            multiple
          />
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("uploadButtonText")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default UploadFilesAction;
