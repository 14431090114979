import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import {
  getExternalDocumentsRolesEndpoint,
  getExternalDocumentsEndpoint,
  downloadExternalPreviewEndpoint,
  uploadExternalDocumentsEndpoint,
  deleteExternalDocumentsEndpoint,
  signExternalDocumentsEndpoint,
} from "@icarius-connection/api";
import { previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

export const getDocumentsSignedAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DATA });
  try {
    let responseDocuments = await getExternalDocumentsEndpoint();
    let responseRoles = await getExternalDocumentsRolesEndpoint();

    let docs = responseDocuments?.data?.files;
    let approvants = responseDocuments?.data?.approvants;
    let roles = responseRoles?.data?.roles;

    dispatch({
      type: actionTypes.GET_DATA_FULFILLED,
      payload: { docs, roles, approvants },
    });
    return responseDocuments;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const previewDocumentsAction = (files) => async (dispatch) => {
  dispatch({ type: actionTypes.PREVIEW_DOCUMENT });
  try {
    let response = await downloadExternalPreviewEndpoint({ files });

    if (response.status !== 200) {
      dispatch({ type: actionTypes.PREVIEW_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.PREVIEW_DOCUMENT_FULFILLED });
    previewFromURL(RESOURCES_ENDPOINT + response.data.path);

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.PREVIEW_DOCUMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteDocumentsAction = (files) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_DOCUMENT });
  try {
    let response = await deleteExternalDocumentsEndpoint({ files });

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DELETE_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.DELETE_DOCUMENT_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_DOCUMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const signDocumentsAction = (files) => async (dispatch) => {
  dispatch({ type: actionTypes.SIGN_DOCUMENT });
  try {
    let response = await signExternalDocumentsEndpoint(files);

    if (response.status !== 200) {
      dispatch({ type: actionTypes.SIGN_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.SIGN_DOCUMENT_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SIGN_DOCUMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

const errorHandler = (status, error, dispatch) => {
  let errorString = "";
  switch (status) {
    case "VALIDATION_ERROR":
      if (error === "INVALID_CODES_TO_ROLE") {
        errorString = getLocalizedString("filesMustStartWithCode");
      }
      if (error === "INVALID_EXTENSION") {
        errorString = getLocalizedString("filesMustBePdf");
      }
      break;
    default:
      errorString = getLocalizedErrorString("defaultException");
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: null }));
}

export const uploadDocumentsAction = (files, name, role) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_DOCUMENT });
  try {
    let formData = new FormData();
    files.forEach(file => {
      formData.append('PDFS', file);
    })
    formData.append('nombre', name);
    formData.append('role', role);

    let response = await uploadExternalDocumentsEndpoint(formData);

    let status = response?.data?.status;
    let error = response?.data?.error;

    if (status !== "OK") {
      errorHandler(status, error, dispatch);
      dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED });
      return;
    }

    dispatch({ type: actionTypes.UPLOAD_DOCUMENT_FULFILLED });
    return response;
  } catch (e) {
    if (e.response.data.error === "INVALID_CODES_TO_ROLE") {
      let stringToUse = getLocalizedString("errorUploadFile")
        .replace("{role}", getLocalizedString(role === "CO" ? "collaborators" : role === "MG" ? "managers" : "employers"));

      dispatch(openSnackbarAction({ msg: stringToUse, severity: "error" }));
      dispatch({
        type: actionTypes.UPLOAD_DOCUMENT_REJECTED,
        payload: e.response.data.invalidFiles
      });
    } else {
      dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED });
    }
    return e.response;
  }
};