import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentsSignedAction,
  previewDocumentsAction,
  signDocumentsAction,
} from "../actions";
import { getApprovants, getDocuments, loading } from "../selectors";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import { VisibilityIcon } from "@icarius-icons";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmPinDialog from "./dialogs/confirmPinDialog";
import DeleteDialog from "./dialogs/deleteDialog";
import UploadFilesDialog from "./dialogs/uploadFilesDialog/uploadFilesDialog";
import { CLEAR_STATE } from "../actionTypes";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const ExternalDocuments = () => {

  const [confirmPinDialogOpen, setConfirmPinDialogOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState(null);
  const [filesToSign, setFilesToSign] = useState(null);

  const dispatch = useDispatch();
  const approvants = useSelector(getApprovants);
  const documents = useSelector(getDocuments);
  const isLoading = useSelector(loading);
  const exportingGridStatus = useSelector(getIsExportingGrid);

  const headerItems = [
    {
      title: getLocalizedString("uploadExternalDocuments"),
      type: 'upload',
      handleClick: () => setUploadDialogIsOpen(true),
    },
    {
      title: getLocalizedString("previewExternalDocuments"),
      child: <VisibilityIcon />,
      handleClick: (gridRef) => handlePreviewDocuments(gridRef),
    },
    {
      title: getLocalizedString("signExternalDocuments"),
      type: 'download',
      handleClick: (gridRef) => handleStartSignDocuments(gridRef),
    },
    {
      title: getLocalizedString("deleteExternalDocuments"),
      type: 'delete',
      handleClick: (gridRef) => handleDeleteDocuments(gridRef),
    },
  ]

  useEffect(() => {
    dispatch(getDocumentsSignedAction());
    return () => { dispatch({ type: CLEAR_STATE }); }
  }, [dispatch])


  const handlePreviewDocuments = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedDocs = gridRef.api.getSelectedRows().map(item => item.file);

    // Si no hay seleccionado, pido seleccionar
    if (selectedDocs.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }
    dispatch(previewDocumentsAction(selectedDocs));
  };

  const handleDeleteDocuments = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedDocs = gridRef.api.getSelectedRows().map(item => item.file);

    // Si no hay seleccionado, pido seleccionar
    if (selectedDocs.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }
    setDeleteDialogIsOpen(true);
    setFilesToDelete(selectedDocs);
  };

  const handleStartSignDocuments = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedDocs = gridRef.api.getSelectedRows().map(item => item.toSign);

    // Si no hay seleccionado, pido seleccionar
    if (selectedDocs.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }
    setConfirmPinDialogOpen(true);
    setFilesToSign(selectedDocs);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
    setFilesToDelete(null);
  }

  const handleCloseConfirmPinDialog = (shouldResetFilesToSign) => {
    setConfirmPinDialogOpen(false);
    shouldResetFilesToSign && setFilesToSign(null); //para que no lo haga cuando toco el submit en el modal
  }

  const handleSignDocuments = (pin, check, nextSigner) => {
    const dataToSend = {
      docs: filesToSign,
      password: pin,
      approvation: check,
      extra_approvant: nextSigner,
    }

    dispatch(signDocumentsAction(dataToSend))
      .then(() => setFilesToSign(null));
  }

  const customItems = (gridRef) =>
    <Grid
      direction="row"
      container
      item
      xs={12}
      md={12}
      justify="flex-end"
      alignItems="center">
      {
        headerItems.map((item, index) =>
          <CustomIconButton
            key={index}
            title={item.title}
            onClick={() => item.handleClick(gridRef)}
            type={item.type}
          >
            {item.child}
          </CustomIconButton>
        )
      }
    </Grid>

  const helpItems = [
    <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Firma Digital de documentos.pdf"} text={getLocalizedString("digitalSignature")} type={"file"} />,
    <MenuItemWithIcon key={6} value={"https://www.youtube.com/embed/kDrBI7MB0GM"} text={getLocalizedString("digitalSignature")} type={"video"} />,
  ]

  return (
    <CommonPage
      helpItems={helpItems}
      menuItems={[customItems]}
      rowData={documents}
      title={getLocalizedString("externalDocumentsLong")}
      gridTitle={getLocalizedString("externalDocumentsLong")}
      columnDefPage={paths.externalDocuments}
      isLoading={isLoading || exportingGridStatus}
      hasExpand
      hasHelp
    >
      {
        uploadDialogIsOpen &&
        <UploadFilesDialog
          open={uploadDialogIsOpen}
          handleClose={() => setUploadDialogIsOpen(false)}
          loading={isLoading || exportingGridStatus}
        />
      }
      {
        deleteDialogIsOpen &&
        <DeleteDialog
          open={deleteDialogIsOpen}
          data={filesToDelete}
          handleClose={handleCloseDeleteDialog}
        />
      }
      {
        confirmPinDialogOpen &&
        <ConfirmPinDialog
          open={confirmPinDialogOpen}
          handleCloseDialog={handleCloseConfirmPinDialog}
          handleConfirm={handleSignDocuments}
          selectionData={approvants}
        />
      }
    </CommonPage>
  );
}

export default ExternalDocuments;
