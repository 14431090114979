import * as actionTypes from "./actionTypes";

const initialState = {
  docs: null,
  approvants: [],
  roles: null,
  gettingData: false,
  gettingPreview: false,
  deletingDocuments: false,
  signingDocuments: false,
  uploadingDocuments: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;

    case actionTypes.GET_DATA:
      return { ...state, gettingData: true };
    case actionTypes.GET_DATA_FULFILLED:
      return {
        ...state,
        gettingData: false,
        docs: action.payload.docs,
        roles: action.payload.roles,
        approvants: action.payload.approvants,
      };
    case actionTypes.GET_DATA_REJECTED:
      return { ...state, gettingData: false };

    case actionTypes.PREVIEW_DOCUMENT:
      return { ...state, gettingPreview: true };
    case actionTypes.PREVIEW_DOCUMENT_FULFILLED:
      return { ...state, gettingPreview: false };
    case actionTypes.PREVIEW_DOCUMENT_REJECTED:
      return { ...state, gettingPreview: false };

    case actionTypes.DELETE_DOCUMENT:
      return { ...state, deletingDocuments: true };
    case actionTypes.DELETE_DOCUMENT_FULFILLED:
      return { ...state, deletingDocuments: false };
    case actionTypes.DELETE_DOCUMENT_REJECTED:
      return { ...state, deletingDocuments: false };

    case actionTypes.SIGN_DOCUMENT:
      return { ...state, signingDocuments: true };
    case actionTypes.SIGN_DOCUMENT_FULFILLED:
      return { ...state, signingDocuments: false };
    case actionTypes.SIGN_DOCUMENT_REJECTED:
      return { ...state, signingDocuments: false };

    case actionTypes.UPLOAD_DOCUMENT:
      return { ...state, uploadingDocuments: true };
    case actionTypes.UPLOAD_DOCUMENT_FULFILLED:
      return { ...state, uploadingDocuments: false };
    case actionTypes.UPLOAD_DOCUMENT_REJECTED:
      return { ...state, uploadingDocuments: false };

    default:
      return state;
  }
}
