import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { deleteDocumentsAction, getDocumentsSignedAction } from "@icarius-pages/externalDocuments/actions";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable"; 
import DialogTransition from "@icarius-common/dialogTransition";

const DeleteDialog = (props) => {

  const { open, data, handleClose } = props;
  const dispatch = useDispatch();

  const handleConfirm = data => {
    dispatch(deleteDocumentsAction(data)).then(() => dispatch(getDocumentsSignedAction()));
    handleClose();
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">  
        {getLocalizedString("atention")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose()} />
      <DialogContent>
        <Typography className="whiteText" variant="h6" gutterBottom>
          {getLocalizedString("confirmDeleteExternalDocuments")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction
          onClick={() => handleClose()}
          text={getLocalizedString("disagree")}
        />
        <ButtonDialogAction
          onClick={() => handleConfirm(data)}
          isAccept
          text={getLocalizedString("agree")}
        />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
