import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { valueIsOfType, valueTypes } from "@icarius-utils/types";
import { MAX_PIN_LENGTH, MIN_PIN_LENGTH } from "@icarius-utils/constants";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CustomTextField from "@icarius-common/input/textField";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { getAppColor } from "src/app/selectors";

const ConfirmPinDialog = (props) => {

  const { open, handleConfirm, handleCloseDialog, selectionData } = props;

  const [check, setCheck] = useState(false);
  const [pin, setPin] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [error, setError] = useState("");

  const color = useSelector(getAppColor);

  useEffect(() => { setError(""); }, [pin]) //cada vez que cambia el pin, limpiar el error

  const handleSubmit = () => {
    validatePin(pin);
    handleConfirm(pin, check, selectedData);
    handleCloseDialog(false);
  };

  const validatePin = (pin) => {
    if (!pin) setError(getLocalizedErrorString("noPin"));
    if (pin.length < MIN_PIN_LENGTH) setError(getLocalizedErrorString("pinMustHaveMinCharacters"));
    if (pin.length > MAX_PIN_LENGTH) setError(getLocalizedErrorString("pinMustHaveMaxCharacters"));
    if (!valueIsOfType(pin, valueTypes.pin)) setError(getLocalizedErrorString("pinMustBeAlphanumeric"));
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("confirmSignExternalDocumentsTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleCloseDialog(true)} />
        <DialogContent>
          <Typography variant={"subtitle1"} className={"whiteText"}>
            {getLocalizedString("confirmSignExternalDocuments")}
          </Typography>
          <div className={"center-input"}>
            <form noValidate>
              {
                <FormControlLabel
                  control={
                    <Checkbox checked={check} onChange={(e) => setCheck(e.target.checked)} />
                  }
                  label="Requerir aprobación del receptor"
                />
              }
              {/* PIN */}
              <div>
                <div style={{ display: "none" }}>
                  <CustomTextField
                    label={""}
                    value={pin}
                    showState
                    color={color}
                    isValid={valueIsOfType(pin, valueTypes.pin)}
                    type={"password"}
                    onChange={value => setPin(value)}
                    maxWidth={true}
                  />
                </div>
                <div style={{ marginTop: 16 }}>
                  <CustomTextField
                    label={getLocalizedString("pin")}
                    value={pin}
                    showState
                    isValid={valueIsOfType(pin, valueTypes.pin)}
                    type={"password"}
                    onChange={value => setPin(value)}
                    maxWidth={true}
                  />
                </div>
              </div>
              <FormControl style={{ width: "100%", marginTop: 20 }}>
                <InputLabel id={`label`}>{"Siguiente firmante"}</InputLabel>
                <Select
                  value={selectedData}
                  labelId={`label`}
                  id={`select`}
                  onChange={(e) => setSelectedData(e.target.value)}
                  margin={"none"}
                >
                  {
                    selectionData.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </form>
          </div>
          {
            Boolean(error) &&
            <Typography variant={"subtitle1"} className={"error-text"}>
              {error}
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={() => handleCloseDialog(true)} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ConfirmPinDialog;
