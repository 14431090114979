import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";

export default [
    {
        pages: [paths.externalDocuments],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.externalDocuments],
        config: {
            headerName: getLocalizedString("date"),
            field: "dateTime",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.externalDocuments],
        config: {
            headerName: getLocalizedString("documentRole"),
            field: "roleText",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.externalDocuments],
        config: {
            headerName: getLocalizedString("nameAndSurname"),
            field: "empName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.externalDocuments],
        config: {
            headerName: getLocalizedString("signDocName"),
            field: "docName",
            filter: "agSetColumnFilter",
        }
    },
]